import axios from "axios"
import DOMPurify from "dompurify"
import { createContext, useState } from "react"
import { v4 as uuid } from 'uuid'
import ApiDocs from "../services/Docs"
import { useSelector } from "react-redux"

export const ChatIaContext = createContext({})

export function ChatIaContextProvider(props) {
  const userState = useSelector((state) => state.auth).userData
  const [defaults, setDefaults] = useState(false)
  const [history, setHistory] = useState([
    {
      message: `<p>
      
      Olá, sou a IA do Tributei e estou aqui para tirar suas dúvidas tributárias sobre o ICMS.
         <br>
          <br>
Estou em versão Beta, o que significa que estou em fase de testes e algumas respostas podem não atender sua expectativa, mas conforme você me dá feedback, dando um joinha positivo ou negativo, eu vou aprendendo e te dou respostas mais assertivas.
  <br>
Nessa fase de teste posso te ajudar com pesquisa de alíquotas internas e MVA para todo o Brasil e sobre dúvidas a respeito da legislação de ICMS de São Paulo.
    <br>
      <br>
    Abaixo deixo sugestões de perguntas que você pode fazer
      </p>
    `,
      user: 'bot'
    }
  ])

  const [prompt, setPrompt] = useState('')
  const [isLoading, setIsLoading] = useState(false)

  async function handlePrompt(promptDefault) {

    if (!prompt && !promptDefault) return

    const historyHuman = {
      message: prompt || promptDefault,
      user: 'human'
    }
    setHistory([...history, historyHuman])
    setPrompt('')
    try {
      setIsLoading(true)
      const response = await axios.post('https://tributei-ai-aihejcfptq-uc.a.run.app/chat', {
        prompt: prompt || promptDefault
      }, {
        withCredentials: true
      })

      const { content } = response.data
      const clean = {
        message: DOMPurify.sanitize(content),
        user: 'bot',
        id: uuid()
      }
      setHistory([...history, historyHuman, clean])
      await ApiDocs.post('/like/log', {
        question: prompt || promptDefault,
        answer: DOMPurify.sanitize(content),
        cnpj_cpf: userState?.user?.cnpj ? userState.user.cnpj : userState?.user?.cpf,
        email: userState?.autenticacao?.email
      })
      setIsLoading(false)
    } catch (error) {
      const clean = {
        message: 'Desculpa, não conseguimos responder a sua pergunta 😢',
        user: 'bot',
        id: uuid()
      }
      setHistory([...history, historyHuman, clean])
      setIsLoading(false)
      await ApiDocs.post('/like/log', {
        question: prompt || promptDefault,
        answer: `Deu error, ${error}`,
        cnpj_cpf: userState?.user?.cnpj ? userState.user.cnpj : userState?.user?.cpf,
        email: userState?.autenticacao?.email
      })
      console.log(error)
    }
  }

  return (
    <ChatIaContext.Provider value={{ history, setDefaults, defaults, handlePrompt, setHistory, setIsLoading, isLoading, setPrompt, prompt }}>
      {props.children}
    </ChatIaContext.Provider>
  )
}